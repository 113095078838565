<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="management.instruction_type.index">
        <template v-slot:title>
          <span>{{
              $t(id != null ? 'instruction_type.edit_instruction_type' : 'instruction_type.create_instruction_type')
            }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex" v-if="instructionTypeProxy">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                 v-for="(value, index) in instructionTypeProxy.translations">
              <text-input
                  :key="index"
                  :title="`${$t('instruction_type.instruction_type_name')}(${getLanguageById(value.language_id).name})`"
                  :model.sync="value.name"
                  :placeholder="`${$t('instruction_type.please_enter_instruction_type_name')}(${getLanguageById(value.language_id).name})`"
                  :is-valid="value != null && value.name.length !== 0 ? true : null"
              ></text-input>

            </div>

            <div class="col-xl-6">
              <div class="d-flex items-end justify-content-start">
                <custom-multi-select
                    :title="$t('expense.expense_logo_name')"
                    :model.sync="logo_id"
                    :options="convertArrayToObjectByKey(logoItems, 'logicalref', 'name')"
                    name="instruction_type_logo_id"
                    :not-list="true"
                    :is-inline="true"
                    :max="1"
                    :required="true"
                    :item-per-row="1"
                    input-width="auto"
                    input-max-width="200px"
                ></custom-multi-select>

              </div>

              <label
                  class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary"><input v-model="is_instruction"
                                                                                                type="checkbox" name=""
                                                                                                :value="true"><span
                  style="border-radius: 8px;"></span>
                {{ $t('instruction_type.is_instruction') }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdateInstructionType"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToCreate"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR, GET_ITEMS,
  ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "InstructionTypeCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      instruction_type: CURRENT_ITEM,
      language: LANGUAGE,
    }),
    isValidToCreate() {
      if (this.instruction_type == null || this.instruction_type.translations == null || this.instruction_type.translations.length === 0) return false;

      for (let i = 0; i < this.instruction_type.translations.length; i++) {
        if (this.instruction_type.translations[i].name == null || this.instruction_type.translations[i].name.length === 0) {
          return false;
        }
      }

      return true;
    },
    instructionTypeProxy: {
      get() {
        if (this.id != null) {
          this.logo_name = this.instruction_type.logo_name;
          this.is_instruction = this.instruction_type.is_instruction;
        }

        return this.instruction_type;
      },
      set(value) {
        this.setInstructionType(value);
      }
    }
  },
  data() {
    return {
      id: null,
      languages: [],
      logoItems: [],
      logo_name: this.id ? this.instructionTypeProxy.logo_name : null,
      is_instruction: this.id ? this.instructionTypeProxy.is_instruction : false,
    };
  },
  methods: {
    ...mapMutations({
      setInstructionType: SET_CURRENT_ITEM,
    }),
    getCacheData () {
      let payload = {
        url: 'api/instruction-type/cache-data',
        returnType: "stateless"
      }

      this.$store.dispatch(GET_ITEMS, payload).then(response => {

        if (response.status) {
          this.logoItems = response.data;
        }
      })
    },
    createOrUpdateInstructionType() {
      let contents = [];

      for (let i = 0; i < this.instructionTypeProxy.translations.length; i++) {
        contents.push({
          language_code: this.instructionTypeProxy.translations[i].language_code ?
              this.instructionTypeProxy.translations[i].language_code :
              this.getLanguageById(this.instructionTypeProxy.translations[i].language_id).code,
          translations: {
            name: this.instructionTypeProxy.translations[i].name,
          }
        })
      }

      let payload = {
        url: this.id == null ? 'api/instruction-type' : 'api/instruction-type/' + this.id,
        id: this.id,
        contents: {
          translations: contents,
          is_instruction: this.is_instruction,
          logo_name: this.logo_name
        },
        redirectRouteName: 'management.instruction_type.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);

    },
  },
  mounted() {
    this.id = this.$route.params.id;

    this.getCacheData();

    if (this.id != null) {
      let payload = {
        url: 'api/instruction-type/' + this.id,
        instruction_type: this.id,
        redirectRouteOnNotFound: 'management.instruction_type.index'
      }
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("instruction_type.instruction_types"), route: "management.instruction_type.index"},
      {title: this.$t(this.id != null ? "instruction_type.edit_instruction_type" : "instruction_type.create_instruction_type")},
    ]);

    this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');

    if (this.id == null) {
      let temp = [];
      for (let i = 0; i < this.systemLanguages.length; i++) {
        temp.push({
          language_id: this.systemLanguages[i].id,
          language_code: this.systemLanguages[i].code,
          language_name: this.systemLanguages[i].name,
          name: '',
        })


      }

      this.setInstructionType({
        translations: temp,
      });
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
